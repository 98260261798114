import React from 'react'
import Layout from '../components/layout'
import Timer from '../components/timer'
import Particles from 'react-particles-js'

const particlesOptions = {
  particles: {
    number: {
      value: 80,
      density: {
        enable: true,
        value_area: 800,
      },
    },
  },
}

class IndexPage extends React.Component {
  state = {
    email: '',
  }
  handleV = e => {
    this.setState({ email: e.target.value })
  }
  render() {
    return (
      <Layout>
        <div className="bgimg">
          <div className="bg-layout">
            <Particles className="particles" params={particlesOptions} />
            <div className="middle">
              <div className="content">
                <h1 className="h1">FURNISPACE</h1>
                <h2 className="h2">COMING SOON</h2>
                <Timer date="09/30/2025" time="00:00"/>
                {/* <p>
                  We will be launch soon, enter your email address and get our
                  early notification.
                </p>
                <input name="email" type="text" placeholder="email address" />
                <button className="button">Submit</button>
                <div id="mc_embed_signup"></div> */}
                {/* <div className="btn-group">
                  <a href="#" className="button-social">
                    <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                      <path
                        fill="#FFF"
                        d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z"
                      />
                    </svg>
                  </a>
                  
                  <a href="#" className="button-social">
                    <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                      <path
                        fill="#FFF"
                        d="M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19M18.5,18.5V13.2A3.26,3.26 0 0,0 15.24,9.94C14.39,9.94 13.4,10.46 12.92,11.24V10.13H10.13V18.5H12.92V13.57C12.92,12.8 13.54,12.17 14.31,12.17A1.4,1.4 0 0,1 15.71,13.57V18.5H18.5M6.88,8.56A1.68,1.68 0 0,0 8.56,6.88C8.56,5.95 7.81,5.19 6.88,5.19A1.69,1.69 0 0,0 5.19,6.88C5.19,7.81 5.95,8.56 6.88,8.56M8.27,18.5V10.13H5.5V18.5H8.27Z"
                      />
                    </svg>
                  </a>              
                  
                  <a href="#" className="button-social">
                    <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                      <path
                        fill="#FFF"
                        d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
                      />
                    </svg>
                  </a>
                </div> */}
              </div>
            </div>
            <div className="bottomleft"></div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
